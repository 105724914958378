import React from 'react';
import { Link } from 'gatsby';
import { v4 } from 'uuid';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import PageHelmet from '../../components/PageHelmet';
import Image from '../../components/Image';
import { ImageOverlay } from '../../utils';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const NewsPageTemplate = ({
  heroImageObject,
  title,
  news,
  smallTitle,
  seoObject,
  imageTitle,
  helmet,
}) => {
  let pinnedNews = news;
  news.forEach((item, i) => {
    console.log(item);
    if(item.node.frontmatter.pinned){
      pinnedNews.splice(i, 1);
      pinnedNews.unshift(item);
    }
  });
  return (
    <div className='page-container container content'>
      {helmet || ''}
      <PageHelmet seoObject={seoObject} />
      <div className='container'>
        <div className='small-title'>{smallTitle}</div>
        <h1 className='page-title'>{title}</h1>
        <BackgroundImage
          imageInfo={heroImageObject.image}
          classes='page-header-image'>
          <div className='image-title'>
            <hr />
            {imageTitle}
          </div>
          <ImageOverlay overlay={heroImageObject.overlay} />
        </BackgroundImage>
      </div>
      <div className='container content'>
        <div className='columns is-variable is-6 is-multiline career-listings'>
          {!!pinnedNews
            ? pinnedNews.map((post) => (
                <div className='column is-4 blog-post' key={v4()}>
                  <Link to={post.node.fields.slug} className='hover-zoom'>
                    <Image
                      imageInfo={{
                        image: post.node.frontmatter.heroImageObject.image,
                      }}
                      classes='property-roll-image'
                      animationClass='hover-zoom'
                    />
                    <h4 className='title blog-title'>
                      {post.node.frontmatter.title}
                    </h4>
                    <div>{post.node.frontmatter.description}</div>
                  </Link>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

const NewsPage = ({ data, location }) => {
  const newsList = data.allMarkdownRemark.edges;
  const pageData = data.markdownRemark.frontmatter;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <NewsPageTemplate
        location={location}
        heroImageObject={pageData.heroImageObject}
        title={pageData.title}
        smallTitle={pageData.smallTitle}
        imageTitle={pageData.imageTitle}
        seoObject={pageData.seoObject}
        news={newsList}
        helmet={
          <MetaComponent
            title={`${pageData.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${pageData.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${pageData.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query NewsPageQueryZHHANS {
    markdownRemark(frontmatter: { templateKey: { eq: "news-page-zhhans" } }) {
      frontmatter {
        title
        smallTitle
        imageTitle
        heroImageObject {
          overlay
          image
        }
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-post-zhhans" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            title
            heroImageObject {
              image
              overlay
            }
            pinned
            description
          }
        }
      }
    }
  }
`;
